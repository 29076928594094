<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>其他收款统计</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>

    <div style="
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;
      ">
      <span>使用时间：</span>
      <el-date-picker type="date" v-model="query.firstTime" ref="startDate" style="width: 12%"></el-date-picker>
      <span style="margin: 0 5px">-</span>
      <el-date-picker type="date" v-model="query.lastTime" style="width: 12%"></el-date-picker>
      <div style="margin: 0 15px">
        <span>选择水站：</span>
        <el-select v-model="query.station" clearable filterable placeholder="选择水站" style="width: 70%">
          <el-option v-for="item in stations" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <el-input v-model="query.search" style="width: 16%; margin-right: 5px" placeholder="输入要查询的备注"></el-input>
      <el-button type="success" icon="iconfont icon-sousuo" @click="handleQuery()">查询</el-button>
    </div>

    <div style="
        padding: 5px 20px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
      ">
      <el-button type="primary" icon="el-icon-plus" style="font-size: 14px"
        @click="dialogVisible = !dialogVisible">添加</el-button>
      <el-button type="primary" icon="iconfont icon-daochuFile" style="font-size: 14px"
        @click="exportClick">导出</el-button>
    </div>

    <div style="padding: 20px">
      <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark"
        style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange">
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column prop="change_time" sortable label="操作时间" align="center">
        </el-table-column>
        <el-table-column prop="create_time" sortable label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="pay_code" sortable label="收款编号" align="center">
        </el-table-column>
        <el-table-column prop="station" label="水站名称" align="center">
        </el-table-column>
        <el-table-column prop="money" sortable label="收款金额" align="center">
        </el-table-column>
        <el-table-column prop="notes" label="备注" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" icon="iconfont icon-daochuFile" style="font-size: 14px"
              @click="handleEdit(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tableBottom">
        <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
          tableFinshNum
        }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
        <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
          tableFinshNum
        }}&nbsp;项</span>
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div> -->
      <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
        :total="total">
      </pagination>
    </div>

    <el-dialog title="添加其他收款" :visible.sync="dialogVisible" width="30%" :before-close="close">
      <div>
        <el-form :model="form" label-width="80px">
          <el-form-item label="选择水站">
            <el-select v-model="query1.station" clearable filterable placeholder="选择水站" style="width: 70%">
              <el-option v-for="item in stations" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款">
            <el-input v-model="query1.money"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="query1.notes" :rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改其他收款" :visible.sync="dialogVisible1" width="30%" :before-close="close1">
      <div>
        <el-form :model="form1" label-width="80px">
          <el-form-item label="选择水站">
            <el-select v-model="query2.station" clearable filterable placeholder="选择水站" style="width: 70%">
              <el-option v-for="item in stations" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款">
            <el-input v-model="query2.money"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="query2.notes" :rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edit">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import pagination from "@/components/pagination/index.vue";
import { getDate } from '../../../../until/utilse';
import { xlixs } from "@/until/xlsx";
export default {
  components: {
    pagination
  },
  data() {
    return {
      total: 0,
      tableData: [],
      query: {
        firstTime: "",
        lastTime: "",
        station: "",
        search: "",
        currentPage: 1,
        currentLength: 20
      },
      stations: [],
      query1: {
        commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
        station: "",
        money: "",
        notes: ""
      },
      query2: {
        id: 0,
        station: "",
        money: "",
        notes: ""
      },
      dialogVisible: false,
      dialogVisible1: false,
      form1: {},

      startDate: "",
      endDate: "",
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.startDate.getTime()
          ); // 禁用小于起始时间的日期
        },
      },
      option1: "",
      option1es: [],
      QueryContent: "",

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,

      option2es: [],
      form: {
        option2: "",
        gathering: "",
        remark: "",
      },
    };
  },
  async mounted() {
    let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
    this.stations = res.map(el => el.enterprise_name);
    this.handleQuery();
  },
  computed: {},
  methods: {
    exportClick() {
      this.$confirm("每次导出最大商品数为10000条？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("/Station/Barrel_Entry/collection_export", { commercial_id: this.query1.commercial_id });
          let arr = res.data;
          console.log(arr);
          arr = arr.map((item) => {
            return Object.values(item);
          });
          console.log(arr);
          xlixs(
            arr,
            [
              "修改时间",
              "创建时间",
              "收款编号",
              "水站名称",
              "收款金额",
              "备注"
            ],
            "其他收款记录"
          );
        })
        .catch((e) => {
          console.log(e);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async edit() {
      // console.log(this.query2);
      this.dialogVisible1 = false;
      let res = (await this.$http.post("/Station/Barrel_Entry/collection_edit", this.query2)).data;
      if (res.status == 'success') {
        this.$message.success("修改成功");
        this.handleQuery()
      } else {
        this.$message("修改失败")
      }
    },
    async add() {
      this.dialogVisible = false;
      let res = (await this.$http.post("/Station/Barrel_Entry/collection", this.query1)).data;
      if (res.status == 'success') {
        this.$message.success("添加成功");
        this.handleQuery()
      } else {
        this.$message("添加失败")
      }
    },
    async handleQuery(page = 1) {
      let query = this.query;
      query.currentPage = page;
      if (query.firstTime) {
        query.firstTime = getDate(query.firstTime);
      }
      if (query.lastTime) {
        query.lastTime = getDate(query.lastTime);
      }
      console.log(query);
      let res = (await this.$http.post("/Station/Barrel_Entry/collection_lst", query)).data;
      this.total = res.count;
      this.tableData = res.data;
      console.log(res);
    },
    reload() {
      location.reload();
    },
    async handleEdit({ id, station, money, notes }) {
      this.query2.id = id;
      this.query2.station = station;
      this.query2.money = money;
      this.query2.notes = notes;
      // console.log(row);
      this.dialogVisible1 = true
      // console.log(id);
      // let res = (await this.$http.post("/Station/Barrel_Entry/collection_edit", query)).data.data;

    },
    close() {
      this.dialogVisible = false
    },
    close1() {
      this.dialogVisible1 = false
    },
    handleDelete() { },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
